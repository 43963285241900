import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
// import { NbComponentShape, NbComponentSize, NbComponentStatus } from '@nebular/theme';

@Component({
  selector: "ngx-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"]
})
export class LandingComponent implements OnInit {
  // statuses: NbComponentStatus[] = [ 'primary', 'success', 'info', 'warning', 'danger' ];

  constructor(private router: Router) {}

  ngOnInit() {
    if (localStorage.getItem('token')){
      this.router.navigateByUrl('/dashboard/console');
    }
    // Imaginary Decker
    // localStorage.setItem("token","eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiJuRk5HcmozTVRaVUNycTVVUUhhR2h0VlF4RjYyIiwiZW1haWwiOiJpbWFnaW5hcnkuZGVja2VyQGdtYWlsLmNvbSIsImlhdCI6MTU3MzU2NzMyNn0.Ogln5JLQtQq3s-grjJO00W3I_Zs1y-zL2e2YG6sdlVNynLvarBLB-lTscf5N0M7JAhiSbuu1hrvgmgIzTB5yCxW8x7xf5PJEL8ufM93PZTPhkkCim4t8CPKizzOgTtjhF5iudnfoVhchwAE_PvzDOYcwA4LIq9S8f3h2rUz1LyI");
    // this.aravind
    // localStorage.setItem('token', 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiJaQXRvQ0NDNW9EZ3hKb2pSWGJZNXFIazBUd1oyIiwiZW1haWwiOiJ0aGlzLmFyYXZpbmRAZ21haWwuY29tIiwiaWF0IjoxNTczODI0MjU3fQ.EZNPSlQnbdg_x9gE0OmCtWK8LhKp8a1GW6_pFtl4OadRNaV6B1nujSMtM8xAq2I2Zlj6743x5LDGkjvnX6_by9r4GhKfV--SWUZ7U62YLk9AX7VqlVoiVqg2renQsaXHqTvHGRMdScGM2m34YzkzxQqZXRotNa4GuqiqMTWi7Ds');
  }
  signup() {
    window.location.href = "https://accounts.oweso.me/signup?continue=wwwify";
  }
}
