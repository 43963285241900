import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
    req = {};
    data = [];
    
  
  constructor(private http: HttpService) { 
    // var req = {};
    // let data = [];// this.service.getData();
    // req['token'] = localStorage.getItem('token');
    // this.http.post('misc/showall', req).subscribe((res: string[])=>{
    //   console.log(res);
    //   data = res;
    //   return data;
    //   }
    // )
    
  }
  
  getData():Observable<object>{
    this.req['token'] = localStorage.getItem('token');
    // return this.req;
    return this.http.post('misc/showall', this.req);
}
}
