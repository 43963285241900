import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpService } from '../../../shared/services/http/http.service';
import { UserDataService } from '../../../shared/services/user/user-data.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { title: 'Log out' } ];
  users: string[];
  data;
  res = {};
  username;
  img;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              
              private router: Router,
              private http: HttpService,
              private user_data: UserDataService) 
              {
                
                menuService.onItemClick()
                .subscribe(bag => {
                  if(bag.item.title === "Log out"){
                    localStorage.clear();
                    // this.router.navigateByUrl('/');
                    window.location.href = 'https://wwwify.now.sh';
                  }
                  else if(bag.item.title === "Profile"){
                    this.router.navigateByUrl('/dashboard/form');

                  }
                }),
                
                // this.data = this.user_data.getData().subscribe(value => {
                //   // console.log(value);
                //   // this.res = value[0];
                //   this.res['token'] = localStorage.getItem('token');
                //   // console.log(this.res);
                //   this.http.post('misc/bio', this.res).subscribe((resp: any) => {
                //     // console.log(resp);
                //     this.username = resp.displayName;
                //     // this.img = resp.photoUrl;
                //     // console.log(resp.photoUrl);
                //   })
                // })
                this.res['token'] = localStorage.getItem('token');
                console.log('res', this.res);
                this.http.post('misc/bio', this.res).subscribe((resp: any) => {
                  this.username = resp.displayName;
                  this.img = resp.photoUrl;
                  console.log(resp);
                })
                
              }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    
    // console.log(this.data);
    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => this.user = users.nick);
    
    

    // const { xl } = this.breakpointService.getBreakpointsMap();
    // this.themeService.onMediaQueryChange()
    //   .pipe(
    //     map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
    console.log(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  consoleRedirect(){
    this.router.navigateByUrl('/dashboard/console');
  }

  formRedirect(){
    this.router.navigateByUrl('/dashboard/form');
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
