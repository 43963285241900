import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import  * as backend from "../../../config.json"; 

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http:HttpClient) { 
    console.log(backend.url);
  }

  post(url, obj){ return this.http.post(backend.url + url,obj);}

  get(url){ console.log(backend.url + url);return this.http.get(backend.url+url);}
}
