import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import  * as backend from "../../../config.json"; 

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http:HttpClient) { }

  validate():Observable<boolean>{
    console.log(localStorage.getItem('token'));
    return this.http.post<boolean>(`${backend.url}auth/validate`, {token: localStorage.getItem('token')});
  }
}
