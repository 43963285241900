
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
  // NbThemeModule,
} from '@nebular/theme';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard } from './shared/guard/auth/auth.guard'
import { LandingComponent } from './landing/landing.component';
import { VerifyComponent } from './verify/verify.component';
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [AppComponent, LandingComponent, VerifyComponent],
  //providers: [AuthGuard],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    
    ThemeModule.forRoot(),

    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    // NbThemeModule.forRoot({ name: 'dark' }),          /// Theme Change
    NgbModule,
    ReactiveFormsModule,
    RouterModule 
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
